import { TrackBusinessProfileViewOptions } from '@oneflare/web-analytics';
import type { InferGetServerSidePropsType, NextPage } from 'next';
import type { ComponentType } from 'react';

import businessPublicProfileServerSideProps from '@oneflare-server-side-props/_businessPublicProfilePage';
import { PageViewType, useTrackPageView } from 'lib/analytics/trackPageViews';
import { publicRuntimeConfig } from 'lib/utils/Environment';
import Error404 from 'pages/Error404';
import BusinessPublicProfile from 'pages/oneflare.com.au/BusinessPublicProfile/BusinessPublicProfile';
import { useBuildMetaDescription, useTrackBusiness } from 'pages/oneflare.com.au/BusinessPublicProfile/utils/hooks';
import HeadContent from 'shared/components/_oneflare/HeadContent/HeadContent';

type PageProps = InferGetServerSidePropsType<typeof businessPublicProfileServerSideProps>;

const withCheckServerCode = <P extends Record<string, any>>(Component: ComponentType) => {
  const ServerCodeHandler = (props: P & Partial<PageProps>) => {
    const { showNotFound } = props;
    if (showNotFound) {
      return <Error404 />;
    }
    return <Component {...props as P} />;
  };
  return ServerCodeHandler;
};

const BusinessPublicProfilePage: NextPage<PageProps> = ({
  getBusinessPublicProfile,
  getReviews,
  params,
  viewParams
}) => {
  useTrackBusiness({ variables: viewParams });
  useTrackPageView(PageViewType.BusinessProfile, null, {businessDetailId: params.businessSlug} as TrackBusinessProfileViewOptions);
  const { business } = getBusinessPublicProfile || {};
  const {
    name: businessName,
    state,
    suburb,
    primaryCategory
  } = business || {};
  const { name: categoryName } = primaryCategory || {};
  const { metaDescription } = useBuildMetaDescription({ business, getReviews });

  return (
    <>
      <HeadContent
        metaDescription={metaDescription}
        pageUrl={`${publicRuntimeConfig.SITE_URL}/b/${params.businessSlug}`}
        pageTitle={`${businessName}, ${categoryName} in ${suburb} ${state} - Oneflare`}
      />
      <BusinessPublicProfile
        business={business}
        businessSlug={params.businessSlug}
        feedbackId={params.feedbackId}
        feedbackStatus={params.feedback}
        staticRender={params.staticRender}
        initialReviewData={getReviews}
      />
    </>
  );
};

const businessProfilePage = withCheckServerCode(BusinessPublicProfilePage);

export { businessProfilePage as default, businessPublicProfileServerSideProps as getServerSideProps };
