import { useQuery } from '@apollo/client';
import AlertContainer from '@oneflare/flarekit/lib/components/AlertContainer';
import isEmpty from 'lodash/isEmpty';
import dynamic from 'next/dynamic';
import {
  memo,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { isMobile } from 'react-device-detect';

import { PageContext } from '@context-providers';
import { DataDogRumAgent } from 'lib/datadog/initializeDatadog';
import { JobFormPage } from 'lib/oneflare-job-form/utils/constants';
import { GET_USER, GetCurrentUserResponse } from 'queries/shared/user';
import NotificationBanner from 'shared/components/NotificationBanner';
import { NotificationBannerMsgStyled } from 'shared/components/NotificationBanner/styled/NotificationBannerStyled';
import { NOTIFICATION_STATUS } from 'shared/components/NotificationBanner/utils';
import PageHeader from 'shared/components/PageHeader/PageHeader';
import { useBreakpoint } from 'shared/utils/hooks';
import {
  BppHeader,
  BppBody,
  BppFooter
} from 'src/pages/oneflare.com.au/BusinessPublicProfile/components';
import { NearbyLocations } from 'src/pages/oneflare.com.au/BusinessPublicProfile/components/BppHeader/components';
import { buildLocalBusinessSchema, buildBusinessProfileTracker } from 'src/pages/oneflare.com.au/BusinessPublicProfile/utils';
import type { BusinessPublicProfileModel, BusinessPublicProfileReviewModel } from 'types/oneflare.com.au/businessPublicProfile';

import {
  BusinessPublicProfileContainerStyled,
  BreadcrumbsStyled,
  BusinessPublicProfileLayoutWrapperStyled,
  ColumnStyled
} from './styled/BusinessPublicProfile';

const DynamicFooter = dynamic(() => import('shared/components/Footer'));
const BppReviewModal = dynamic(() => import('src/pages/oneflare.com.au/BusinessPublicProfile/components/ReviewModal'));

const ProfileNotifcationBanner = ({
  feedbackStatus
}: {
  feedbackStatus: string
}) => {
  switch (feedbackStatus) {
    case 'published':
      return (
        <NotificationBanner status={NOTIFICATION_STATUS.SUCCESS}>
          <NotificationBannerMsgStyled>
            Your review has been published
          </NotificationBannerMsgStyled>
        </NotificationBanner>
      );
    case 'verifying':
      return (
        <NotificationBanner status={NOTIFICATION_STATUS.INFO}>
          <NotificationBannerMsgStyled>
            Thanks for confirming, your review is with our support team to verify
          </NotificationBannerMsgStyled>
        </NotificationBanner>
      );
    default:
      return null;
  }
};

const MemoizedProfileNotificationBanner = memo(ProfileNotifcationBanner);
// #endregion NotificationBanner

type BusinessPublicProfileProps = {
  feedbackId: string;
  businessSlug: string;
  staticRender: boolean;
  feedbackStatus: string;
  business: BusinessPublicProfileModel;
  initialReviewData: BusinessPublicProfileReviewModel;
};

const BusinessPublicProfile = ({
  business,
  feedbackId,
  staticRender,
  businessSlug,
  feedbackStatus,
  initialReviewData
}: BusinessPublicProfileProps) => {
  const [showReviewModal, setShowReviewModal] = useState(Boolean(feedbackId));
  const reviewsSectionRef = useRef<HTMLElement | null>(null);
  const alertsRef = useRef<AlertContainer>(null);
  const initialContext = useMemo(() => ({
    AlertContainer: alertsRef
  }), [alertsRef]);

  const { data: userData } = useQuery<GetCurrentUserResponse>(GET_USER, {
    onError: (err) => DataDogRumAgent.addRumError(err, 'Oneflare | Business Public Profile | GET_USER query')
  });

  const currentUser = userData?.currentUser;
  const isLogin = Boolean(currentUser);
  const isSameBusiness = business.id === Number(currentUser?.user?.business?.id);
  const isStickyHeader = !isLogin || !currentUser?.user?.isBusiness;
  const tracker = useMemo(() => buildBusinessProfileTracker(business.id), [business.id]);
  const handleClose = () => setShowReviewModal(false);

  const breadcrumbsNav = business.breadcrumbsV2?.map(({ title, url }) => ({
    title,
    link: url
  }));

  useEffect(() => tracker.interaction(
    'pageView',
    { label: business.featuredListing ? 'premium_business_profile' : 'business_profile' }
  ), [business.featuredListing, tracker]);

  // Show nearby locations based on user landing on mobile
  // or desktop preventing server/client html mismatch
  const isLgUp = useBreakpoint('lg');
  const [showNearbyLocations, setShowNearbyLocations] = useState(() => {
    if (!business.primaryCategory) return false;
    if (isMobile) return true;
    return false;
  });

  useEffect(() => {
    setShowNearbyLocations(!isLgUp);
  }, [isLgUp]);

  return (
    <>
      <script
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(buildLocalBusinessSchema(business, initialReviewData))
        }}
      />
      <MemoizedProfileNotificationBanner feedbackStatus={feedbackStatus} />
      <PageHeader jobFormInitiatedPageName={JobFormPage.BusinessProfile} />
      <PageContext.Provider value={initialContext}>
        <AlertContainer className="alert" ref={alertsRef} />
        <BusinessPublicProfileContainerStyled>
          {!isEmpty(business.breadcrumbsV2) && (
            <BreadcrumbsStyled lastItemIsLinked navigation={breadcrumbsNav} />
          )}
          <BusinessPublicProfileLayoutWrapperStyled>
            <ColumnStyled>
              <BppHeader
                business={business}
                businessSlug={businessSlug}
                staticRender={staticRender}
                isStickyHeader={isStickyHeader}
                reviewsSectionRef={reviewsSectionRef}
                tracker={tracker}
              />
            </ColumnStyled>
            <ColumnStyled>
              <BppBody
                business={business}
                initialReviewData={initialReviewData}
                isLogin={isLogin}
                isSameBusiness={isSameBusiness}
                reviewsSectionRef={reviewsSectionRef}
                tracker={tracker}
              />
            </ColumnStyled>
            <ColumnStyled>
              <BppFooter isStickyHeader={isStickyHeader} />
            </ColumnStyled>
            {showNearbyLocations && (
            <ColumnStyled>
              <NearbyLocations
                category={business.primaryCategory.name}
                nearbyCategoryLocations={business.nearbyCategoryLocations}
              />
            </ColumnStyled>
            )}
          </BusinessPublicProfileLayoutWrapperStyled>
        </BusinessPublicProfileContainerStyled>
        {feedbackId && (
          <BppReviewModal
            feedbackId={feedbackId}
            handleClose={handleClose}
            show={showReviewModal}
          />
        )}
      </PageContext.Provider>
      <DynamicFooter />
    </>
  );
};

export default memo(BusinessPublicProfile);
